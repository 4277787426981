<template>
  <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="500px" @open="open"
      @closed="closeModal">
    <ch-form
        :render-option="option"
        :model="modalForm"
        ref="form"
        :rules="rules"
        :props="{ labelWidth: '120px', paddingRight: '0px' }"
    >
      <template slot="serviceTypeSet">
        <el-cascader
            ref="serviceTypeSetCascader"
            v-model="serviceTypeSetId"
            :options="treeList"
            :props="{ multiple: true, value: 'id',label: 'name' }"
            collapse-tags
            clearable
            placeholder="请选择服务类型"
            :show-all-levels="false"
            size="small"
            @change="handleChange">
        </el-cascader>
      </template>
    </ch-form>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok" @click="handleModal" :loading="loading">确定</ch-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function,
    }
  },
  data() {
    return {
      modalForm:{
        serviceTypeSet: [],
        serviceTypeSetStr: '',
        durationDays: '',
        requiredOrders: '',
        bonusAmount: '',
        requiredRating: '',
      },
      title: '',
      isOpenModal: false,
      loading: false,
      rules: {
        serviceTypeSet: [
          { required: true, message: '请选择服务类型', trigger: ['blur', 'change'] },
        ],
        durationDays: [
            { required: true, message: '请输入累计天数获奖', trigger: ['blur', 'change'] },
        ],
        requiredOrders: [
          { required: true, message: '请输入累计单数获奖', trigger: ['blur', 'change'] },
        ],
        bonusAmount: [
          { required: true, message: '请输入奖励金额', trigger: ['blur', 'change'] },
        ],
        requiredRating: [
          { required: true, message: '请选择是否五星好评', trigger: ['blur', 'change'] },
        ],
        startTime: [
          { required: true, message: '请选择规则开始时间', trigger: ['blur', 'change'] },
        ],
        expiryDate: [
          { required: true, message: '请选择规则到期时间', trigger: ['blur', 'change'] },
        ],
      },
      serviceTypeSetId: [],
      treeList: [],
    }
  },
  computed: {
    option() {
      return [
        {type: 'slot', label: '服务类型', slotName: 'serviceTypeSet', prop: 'serviceTypeSet',},
        {type: 'number', label: '累计天数', prop: 'durationDays', placeholder: '请输入累计天数获奖',},
        {type: 'number', label: '累计单数', prop: 'requiredOrders', placeholder: '请输入累计单数获奖',},
        {type: 'number', label: '奖励金额', prop: 'bonusAmount', placeholder: '请输入奖励金额 ',precision: 2,},
        {type: 'radio', label: '是否五星好评', prop: 'requiredRating', option: [{label: '是', value: 1}, {label: '否', value: 0}],},
        {type: 'date', label: '规则开始时间', prop: 'startTime', placeholder: '请选择规则开始时间'},
        {type: 'date', label: '规则到期时间', prop: 'expiryDate', placeholder: '请选择规则到期时间'},
      ]
    }
  },
  methods: {
    open() {
      this.getTreeList();
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      })
    },
    openAddModal(){
      this.isOpenModal = true;
      this.title = '新增接单奖励';
    },
    openEditModal(row){
      this.isOpenModal = true;
      this.title = '编辑接单奖励';
      this.modalForm = JSON.parse(row);
      this.serviceTypeSetId = JSON.parse(this.modalForm.serviceTypeSetStr);
      delete this.modalForm.companyId
    },
    getTreeList(){
      this.$curl.get('/hm/classify/tree').then(res=>{
        this.treeList = res.data
      })
    },
    handleChange(value){
      this.modalForm.serviceTypeSetStr = JSON.stringify(value);
      let checkedNodeList = this.$refs.serviceTypeSetCascader.getCheckedNodes();
      let serviceTypeSet = checkedNodeList.filter(item => item.children.length === 0)
      this.modalForm.serviceTypeSet = serviceTypeSet.map(item => {
        return item.value
      })
    },
    handleModal(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          if(this.modalForm.id){
            this.$curl.post('/hm/hmRewardRule/edit', this.modalForm).then(() => {
              this.$message.success('编辑成功');
              this.getListFunction();
              this.closeModal();
            }).finally(() => {
              this.loading = false;
            })
          }else {
            this.$curl.post('/hm/hmRewardRule/add', this.modalForm).then(() => {
              this.$message.success('新增成功');
              this.getListFunction();
              this.closeModal();
            }).finally(() => {
              this.loading = false;
            })
          }
        }
      })
    },
    closeModal() {
      setTimeout(() => {
        this.modalForm ={
          serviceTypeSet: [],
          serviceTypeSetStr: '',
          durationDays: '',
          requiredOrders: '',
          bonusAmount: '',
          requiredRating: '',
        }
      },100)
      this.serviceTypeSetId = []
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>