<template>
  <div>
    <ch-layout button page search>
      <template v-slot:button>
        <ch-button @click="$refs.modal.openAddModal()">新增</ch-button>
      </template>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="$refs.modal.openEditModal(JSON.stringify(scope.row))">编辑</ch-button>
            <ch-button type="link" @click="openDetail(scope.row.id)">明细</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <modal ref="modal" :get-list-function="getTableList"/> />
  </div>
</template>

<script>
import modal from "./modal.vue"
export default {
  components: {
    modal,
  },
  data(){
    return{
      // 搜索相关
      searchForm: {
        serviceTypeSet:[],
        expiryDateEnd:null,
        expiryDateStart:null,
        status:null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      status:['有效期','已过期'],
    }
  },
  computed:{
    searchOption: function (){
      return [
        {
          type: "select", label: "服务类型：", prop: "serviceTypeSet", placeholder: "请选择服务类型", width: '200px',
          props:{multiple:true,filterable:true,clearable: true,collapseTags: true},
          name: "label", value: "value", option: [
            {value: 0, label: "有效期"},
            {value: 1, label: "已过期"},
          ]
        },
        {type: 'time', label: '规则到期：', prop: 'time',},
        {
          type: "select", label: "状态：", prop: "status", placeholder: "请选择状态",labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: 0, label: "有效期"},
            {value: 1, label: "已过期"},
          ]
        },
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.resetSearch},
          ]},

      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "服务类型", prop: "serviceTypeName", showOverflowTooltip: true,},
        { column: "text", label: "累计天数", prop: "durationDays", showOverflowTooltip: true},
        { column: "text", label: "累计单数", prop: "requiredOrders", showOverflowTooltip: true},
        { column: "text", label: "奖励金额", prop: "bonusAmount", showOverflowTooltip: true,sign: '元'},
        { column: "text", label: "五星好评", prop: "_requiredRating", showOverflowTooltip: true},
        { column: "text", label: "开始时间", prop: "startTime", showOverflowTooltip: true},
        { column: "text", label: "规则到期", prop: "expiryDate", showOverflowTooltip: true},
        { column: "text", label: "状态", prop: "status",showOverflowTooltip:true, yes: '已过期', no: '有效期'},
        { column: "slot", label: "操作", slotName: "handle", width: "100" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      this.getTableList()
    },
    resetSearch() {
      this.getTableList()
    },
    // 列表数据
    async getTableList() {
      this.isTableLoading = true;
      let params = {
        ...this.searchForm,
      }
      params.serviceStaffId = this.$route.query.id
      this.$refs?.page?.getServerList("/hm/hmRewardRule/list",params).then((res) => {
        this.tableList = res.rows.map((item) => {
          return {
            ...item,
            _requiredRating: item.requiredRating ? '是': '否',
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 跳转详情
    openDetail(id){
      this.$router.push({ name: "takeReward", query: { id } });
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-select__input {
  padding-left: 12px;
}
</style>